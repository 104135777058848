@import "variables";
@import "flex";
@import "mixins";

@mixin btn-theme($background-color, $border-color, $text-color) {
  background-color: $background-color;
  border: 1px solid $border-color;
  color: $text-color;
}

@mixin btn-sizing($height, $width: unset) {
  height: $height;
  width: $width;
}

@mixin button-link($text-color) {
  color: $text-color;
  text-decoration: underline;
  transition: $hoverTransition;

  &:hover {
    filter: brightness(80%);
  }
}

.button-link-blue {
  @include button-link($smash-blue);
  cursor: pointer;
}

.button-link-red {
  @include button-link($main-primary-color);
  cursor: pointer;
}

@mixin btn-new {
  cursor: pointer;
  border-radius: 0.3rem;
  min-height: 2.8rem;
  max-width: 100%;
  padding: 0.5rem 1.6rem;
  transition: all 100ms, box-shadow 240ms;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.btn-new {
  @include btn-new;
}

.btn-v3 {
  @include btn-new;
  padding: 0.9rem 1.6rem;
  border-radius: 2rem;

  @include mobile {
    border-radius: 3rem;
  }
}

.btn {
  border-radius: $brd-rad-small;
  cursor: pointer;
  height: 4rem;
  min-width: 100px;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.06rem;
  padding: 0 1.5rem;
  width: 100%;
  @include justify-content(center);
  @include align-items(center);
  @include flexbox;

  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }
}

.btn-rounded {
  border-radius: 20px;
  cursor: pointer;
  height: 3.8rem;
  min-width: 100px;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.06rem;
  padding: 2rem 1.9rem;
  @include justify-content(center);
  @include align-items(center);
  @include flexbox;

  &:disabled {
    cursor: initial;
    opacity: 0.5;
  }
}

.primary {
  @include btn-theme($main-primary-color, none, $bg-white);
}

.green {
  @include btn-theme($green-bg-color, $green-bg-color, $main-light-color);

  &:hover:not([disabled]) {
    color: #e4fff7;
  }
}

.red {
  @include btn-theme($red, $red, $main-light-color);

  &:hover:not([disabled]) {
    color: #f7ccce;
  }
}

.red-light {
  @include btn-theme($main-light-color, $grey-light, $grey-medium);

  &:hover {
    color: $red;
    border: solid 1px $red;
  }
}

.white-outline {
  @include btn-theme(transparent, $main-light-color, $main-light-color);

  &:hover {
    color: $grey-medium;
    border: solid 1px $grey-medium;
  }
}

.dark {
  @include btn-theme($grey-dark, $grey-dark, $main-light-color);

  &:hover:not([disabled]) {
    color: #cccccc;
  }
}

.light {
  @include btn-theme($main-light-color, $main-medium-color-ligthen, $main-medium-color-ligthen);

  &:hover {
    border-color: rgba(0, 0, 0, 0.6);
    color: rgba(0, 0, 0, 0.6);
  }
}

.white {
  @include btn-theme($main-light-color, $premium-grey-border-color, $grey-dark);
}

.dark-filaire {
  @include btn-theme($main-light-color, $grey-dark, $grey-dark);
}

.auto {
  width: auto;
}

%smash-button-dark {
  background: $grey-dark;
  border: none;
  color: $bg-white;
  width: 100px;
  height: 35px;
}

%smash-button-white {
  border-radius: $brd-rad-small;
  @extend %brd-grey-light;
  background: $bg-white;
  color: $grey-dark;
  width: 100px;
  height: 35px;

  &:hover {
    background-color: $grey-lighter;
  }
}

%cross-modal-close {
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:before,
  &:after {
    transition: all 0.15s ease-out;
    position: absolute;
    content: " ";
    height: 100%;
    width: 2px;
    background-color: $grey-light;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {

    &:before,
    &:after {
      background-color: $black;
    }
  }
}